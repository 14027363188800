import React, { FC } from 'react';
import SigninContent from '../../components/SigninContent';
import Head from 'next/head';

const Login: FC = () => {
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <SigninContent />
    </>
  );
};

export default Login;
